import React, { useState } from "react";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import DiamondIcon from "@mui/icons-material/Diamond";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import logo from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { primary } from "../SDK/theme";

const navItems = [
  { name: "Home", path: "/" },
  { name: "Rooms & Suite", path: "/rooms-and-suits" },
  { name: "Services", path: "/services" },
  // { name: "Testimonials", path: "/#testimonials" },
  { name: "About us", path: "/about-us" },
  {name:"Gallery",path:"/gallery" },
  // { name: "BLOG", path: "/#blog" },
];

const Header = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 1 }}>
        <Link to={"/"}>
          <img src={"/logo.png"} style={{ width: "60px", margin: "10px" }} />
        </Link>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item?.name} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link to={item?.path}>
                <ListItemText primary={item?.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}

        <Button
          key={5}
          sx={{
            color: primary?.white,
            //margin: "0em 0.5em 0em 0.5em",
            boxShadow: "none",
            border: "none",
            fontWeight: "500",
            background: primary.main,
          }}
          href="tel:9237385044"
          hrefLang={"en"}
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          <PhoneInTalkIcon
            sx={
              {
                //color: "white",
                //marginRight: "2px",
              }
            }
          />{" "}
          9237385044
        </Button>
      </List>
    </Box>
  );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const query = useLocation();
  console.log("query", query);
  return (
    <>
      <div className="flex justify-between items-center p-2 pt-2 container m-auto"  >

      <div className="w-20 md:w-20" style={{textAlign:"center", position:"relative", }}>
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
      
        <div className={`hidden md:block ${isMenu ? "block" : "hidden"}`} >
          <ul className="list-none capitalize flex" >
            <Link to={"/"}>
              <li
                className="px-4 relative cursor-pointer"
                style={{
                  background:
                    query.pathname === "/" ? primary.main : primary.white,
                  padding: "10px",
                  borderRadius: "4px",
                  color:
                    query.pathname === "/" ? primary.white : primary.mainText,
                }}
              >
                Home
              </li>
            </Link>
            <Link to={"/services"}
            style={{
              background:
                query.pathname === "/services" ? primary.main : primary.white,
              padding: "10px",
              borderRadius: "4px",
              color:
                query.pathname === "/services" ? primary.white : primary.mainText,
            }}
            >
              <li className="px-4 relative cursor-pointer">Services</li>
            </Link>
            <Link 
            style={{
              background:
                query.pathname === "/rooms-and-suits" ? primary.main : primary.white,
              padding: "10px",
              borderRadius: "4px",
              color:
                query.pathname === "/rooms-and-suits" ? primary.white : primary.mainText,
            }}
            to={"/rooms-and-suits"}>
              <li className="px-4 relative cursor-pointer">Rooms & Suite</li>
            </Link>
            <Link to={"/about-us"}
            style={{
              background:
                query.pathname === "/about-us" ? primary.main : primary.white,
              padding: "10px",
              borderRadius: "4px",
              color:
                query.pathname === "/about-us" ? primary.white : primary.mainText,
            }}
            >
              <li className="px-4 relative cursor-pointer">About us</li>
            </Link>
            <Link 
            style={{
              background:
                query.pathname === "/gallery" ? primary.main : primary.white,
              padding: "10px",
              borderRadius: "4px",
              color:
                query.pathname === "/gallery" ? primary.white : primary.mainText,
            }}
            to={"/gallery"}>
              <li className="px-4 relative cursor-pointer">Gallery</li>
            </Link>

            

            {/* <Link to={"/Category"}><li className="px-4 relative cursor-pointer">Category</li></Link> */}
            {/* <Link to={"/SingleHotelView"}><li className="px-4 relative cursor-pointer">Virtual Tour</li></Link> */}
          </ul>

          
        </div>
        
        

        <div className="hidden md:block">
          <Button
            key={5}
            sx={{
              // color: primary?.main,
              margin: "0em 0.5em 0em 0.5em",
              boxShadow: "none",
              border: "none",
              fontWeight: "500",
              background: "#00BCFD",
            }}
            href="tel:9237385044"
            hrefLang={"en"}
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            <PhoneInTalkIcon
              sx={{
                //color: "white",
                marginRight: "2px",
              }}
            />{" "}
            9237385044
          </Button>
          {/* <button className="px-4 p-3 uppercase font-normal">
            <PhoneInTalkIcon/>
            <span className="p-1">Call for Reservation</span>
          </button> */}
          {/* <button className="px-4 p-3 bg-[#d27548] uppercase text-white font-normal rounded">
            book now
          </button> */}
        </div>
        <div className="block md:hidden px-8 p-4" onClick={handleDrawerToggle}>
          <DensityMediumIcon />
        </div>
      </div>
      <div className="reletive">
        {/* <div className={`${isMenu ? "block" : "hidden"} bg-[#d27548] text-white md:hidden absolute rounded left-0 right-0`}>
        <ul className="list-none capitalize flex flex-col">

         
        <Link to={"/"}><li className="p-1 text-center cursor-pointer hover:bg-orange-700" onClick={() => setIsMenu(false)}>Home</li></Link>
        <a href="/about-us"><Link to={"/about-us"}><li className="p-1 text-center cursor-pointer hover:bg-orange-700" onClick={() => {setIsMenu(false)
        console.log("Hey")} } >About us</li></Link></a>
        <Link to={"/about-us"}><li className="px-4 relative cursor-pointer" >About us</li></Link>
        
        <Link to={"/Category"}><li className="p-1 text-center cursor-pointer hover:bg-orange-700" onClick={() => setIsMenu(false)}>Category</li></Link>
        <Link to={"/"}><li className="p-1 text-center cursor-pointer hover:bg-orange-700" onClick={() => setIsMenu(false)}>Virtual Tour</li></Link>
        </ul>
      </div> */}

        <Box component="nav">
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "50%",
                borderRadius: "0px 16px 0px 0px",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </div>
    </>
  );
};

export default Header;

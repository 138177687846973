
import { useFormik } from "formik";
import { useState } from "react";
import {postExcelLead} from "../../../apiService/excelLead"

const useContactForm = () => {
  //const { showSnackbar } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);

  const form = useFormik({
    validateOnChange: true,
    initialValues: {
      name: "",
      mobile: "",

      message: "",
    },

    validate: (values) => {
      const errors = { ...values?.errors };

      if (!values?.mobile?.toString()) {
        errors.mobile = "Mobile Number is Mandatory";
      }
      if (values?.mobile?.toString()?.length !== 10) {
        errors.mobile = "Enter a valid 10 digit Mobile Number";
      }
      if (values?.mobile && values?.mobile[0] < 5) {
        errors.mobile = "Enter Valid Mobile Number";
      }
      if (values?.name?.toString()?.length === 0) {
        errors.name = "Please Enter Your Name";
      }

      return errors;
    },

    onSubmit: (values) => {
      console.log("values", values);
      setLoading(true);
      setFormSubmit(true);

      postExcelLead(values)
        .then((res) => {
          console.log(res, "res");
          setLoading(false);
          localStorage.setItem("phone", values?.mobile);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    },
  });
  return {
    form,
    loading,
    formSubmit,
  };
};

export default useContactForm;

import Showcase from "./Showcase";
import { HotelDetail } from "../Detail/HotelDetail";
import WrapperContainer from "./WrapperContainer";


const PackageDetail = () => {
  return (
    <div className="bg-[#00BCFD]">
    <WrapperContainer> 
      <div className="flex flex-col md:flex-row items-center p-4 py-12 text-[#fcf2de]">
        <div className="w-1/2">
          <span>Packages</span>
          <h3 className="text-4xl">Enjoy your Stay</h3>
        </div>
        <div className="w-1/2">
          <p className="text-[16px]">
          Hotel Stalwart Jajati, conveniently located near Bhubaneswar railway station, boasts 40 Executive Rooms and Suites. This 3-star hotel offers air-conditioned accommodations with free WiFi and private bathrooms. Each room features a 32" LED TV with satellite channels, an electric coffee/tea maker, and a direct dial telephone. The bathrooms are equipped with a geyser system for hot water availability. Guests can enjoy room service and access the 24-hour front desk. Additionally, the hotel offers the O' Kareem Restaurant for dining convenience.
          </p>
        </div>
      </div>
      <div
        id="showcase-Section"
        className="container m-auto flex flex-wrap flex-col md:flex-row justify-between items-center"
      >
        {HotelDetail.map((detail) => (
          <Showcase
        
            img={detail.image}
            title={detail.title}
            desc={detail.desc}
            button={detail.button}
          />
        ))}
      </div>
      </WrapperContainer> 
    </div>
  );
};

export default PackageDetail;

import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import img1 from "../assets/stalwart/hotel1.webp"
import img2 from "../assets/stalwart/hotel3.webp"
import img3 from "../assets/stalwart/hotel4.webp"
import img4 from "../assets/stalwart/hotel6.webp"
import img5 from "../assets/stalwart/hotel7.webp"
import img6 from "../assets/gallery-image/galery7.webp"
import img7 from "../assets/gallery-image/galery8.webp"
import img8 from "../assets/gallery-image/galery9.webp"
import img9 from "../assets/gallery-image/galery6.webp"
import img10 from "../assets/gallery-image/galery4.webp"
import img11 from "../assets/gallery-image/galery12.webp"
import img12 from "../assets/gallery-image/galery1.webp"

import { useMediaQuery } from '@mui/material';
import { theme } from '../SDK/theme';




export default function Gallery() {
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    
    <ImageList   cols={ matchDownMd?1:3} sx={{ padding: '20px' }}  >
      {itemData.map((item) => (
        <ImageListItem key={item.img}  >
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    
  );
}       

const itemData = [
  {
 img:img2,
 title:'room'   
    
  },
  {
    img:img1,
    title: 'Burger',
  },
  {
    img:img3,
    title: 'Camera',
  },
  {
    img:img5,
    title: 'Coffee',
  },
  {
    img: img4,
    title: 'Hats',
  },
  {
    img: img6,
    title: 'Honey',
  },
  {
    img: img7,
    title: 'Basketball',
  },
  {
    img: img8,
    title: 'Fern',
  },
  {
    img: img9,
    title: 'Mushrooms',
  },
  {
    img: img10,
    title: 'Tomato basil',
  },
  {
    img: img11,
    title: 'Sea star',
  },
  {
    img: img12,
    title: 'Bike',
  },
];
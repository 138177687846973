import { Box, Collapse, Grow, Zoom } from "@mui/material";
import image from "../assets/stalwart/hotel1.webp";
import TypeWriter from "../SDK/typeWriter";

export function HeroSection() {
  return (
    <div className="relative w-full pb-8">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="flex flex-col justify-center px-4 py-12 md:py-16 lg:col-span-7 lg:gap-x-6 lg:px-6 lg:py-24 xl:col-span-6">
          <div>
            <img src={"/stalwart-logo.webp"}  onClick={()=>window.location.href="https://www.stalwartprojects.in/"}/>
          </div>
          <h1 className="mt-8 text-3xl font-bold tracking-tight md:text-4xl lg:text-6xl">
            Welcome to 
            <span className="text-[#00BCFD] capitalize">
              <TypeWriter infoText={"Hotel Stalwart Jajati"} />
            </span>
          </h1>

          <div className="mt-8 flex text-white max-w-max items-center space-x-2 rounded-full bg-[#00BCFD] p-1">
            <div className="rounded-full bg-[#7c2b10d0] p-1 px-2">
              <p className="text-sm font-medium">Luxurious&apos;</p>
            </div>
            <p className="text-sm font-medium">Find Your Hotel &rarr;</p>
          </div>
          <p className="mt-8 text-lg">
            Hotel Stalwart Jajati, proudly owned by Stalwart Projects Private
            Limited, embodies our commitment to excellence. With over 13 years
            of expertise in real estate and construction, Stalwart Projects Pvt
            Ltd has consistently delivered state-of-the-art facilities to our
            esteemed clientele. Our success story is defined by our unwavering
            dedication to honesty, quality, and punctuality. At Hotel Stalwart
            Jajati, we strive to curate unforgettable experiences for our guests
            through unparalleled amenities and exceptional service.
          </p>
        </div>

        <div className="relative lg:col-span-5 lg:-mr-8 xl:col-span-6">
          <Zoom in={true} style={{ transitionDelay: "500ms" }}>
         <Box style={{marginTop:"15%", }}
              >
                 <iframe style={{width:"100%",height:"550px"}} src="https://www.youtube.com/embed/nk5iMydGvRQ?si=V2H8JO6J6y7jVhF1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          
              </Box>
                {/* <img
              className="aspect-[3/2] bg-gray-50 rounded object-cover lg:aspect-[4/3] lg:h-[700px] xl:aspect-[16/9]"
              src={image}
              alt=""
            /> */}
          </Zoom>
        </div>
      </div>
    </div>
  );
}

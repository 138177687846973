import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./page/Home";
import CategoryPage from "./page/CategoryPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorPage } from "./page/ErrorPage";
import SingleHotelView from "./page/SingleHotelView";
import GlobleCotext from "./contextApi/GlobleContex";
import Gallery from "./page/Gallery"
import { CustomContextProvider } from "./context/context";
import { MemoizedContactUs } from "./modules/ContactUs/ContactUs";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./SDK/theme";
import axios from "axios";
import AboutUsDetailsComponent from "./page/Aboutus";
import Services from "./page/Services";
import RoomsAndSuits from "./page/RoomsAndSuits";


// axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_PROD_URL;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
function App() {
  return (
 <>
  <CustomContextProvider>
    <GlobleCotext>
    <ThemeProvider theme={theme}>
        {" "}
        <CssBaseline>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/rooms-and-suits" element={<RoomsAndSuits/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/" element={<Home/>}/>
          <Route path="/about-us" element={<AboutUsDetailsComponent/>}/>
          <Route path="/gallery" element ={<Gallery/>} />
          {/* <Route path="/Category" element={<CategoryPage/>}/>
          <Route path={`/SingleHotelView/:id<`} element={<SingleHotelView/>} /> */}
          <Route path="contact-us" element={<MemoizedContactUs/>}/>
          <Route path="*" element={<ErrorPage/>}/>
        </Routes>
      </BrowserRouter>
      </CssBaseline>
      </ThemeProvider>
      <Footer />
      </GlobleCotext>
   </CustomContextProvider>
</>
  );
}

export default App;

import WifiIcon from "@mui/icons-material/Wifi";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import SecurityIcon from "@mui/icons-material/Security";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import CastleIcon from "@mui/icons-material/Castle";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import { Grid } from "@mui/material";

export default function Facilities() {
  const facility = [
    {
      id: 1,
      name: "WIFI",
      icon: <WifiIcon style={{ color: "#00BCFD", marginRight: "10px" }} />,
    },
    {
      id: 2,
      name: "Room Service",
      icon: <RoomServiceIcon style={{ color: "#00BCFD" }} />,
    },
    {
      id: 1,
      name: "STREAMING TV",
      icon: <RestaurantIcon style={{ color: "#00BCFD" }} />,
    },
    {
      id: 1,
      name: "ON-SITE RESTAURANT",
      icon: <LiveTvIcon style={{ color: "#00BCFD" }} />,
    },
    {
      id: 1,
      name: "ON-SITE RESTAURANT",
      icon: <RestaurantIcon style={{ color: "#00BCFD" }} />,
    },
    {
      id: 1,
      name: "ON-SITE RESTAURANT",
      icon: <RestaurantIcon style={{ color: "#00BCFD" }} />,
    },
  ];

  const amenitiesData = [
    {
      title: "WIFI",
      desc: "Complimentary Wi-Fi",
      icon: <WifiIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Wi-Fi
    },
    {
      title: "Room Service",
      desc: "Convenient Room Service",
      icon: <RoomServiceIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Room Service
    },
    {
      title: "STREAMING TV",
      desc: "Streaming TV Access",
      icon: <LiveTvIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Streaming TV
    },
    {
      title: "Security",
      desc: "Enhanced Security Measures",
      icon: <SecurityIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Security
    },
    {
      title: "WEDDING HALLS",
      desc: "Elegant Wedding Venues",
      icon: <CastleIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Wedding
    },
    {
      title: "Pick Up And Drop",
      desc: "Hassle-Free Pick-Up and Drop-Off Services",
      icon: <ConnectingAirportsIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Pick Up and Drop
    },
    {
      title: "FREE PARKING",
      desc: "Free Parking Facilities",
      icon: <LocalParkingIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Free Parking
    },
    {
      title: "ON-SITE RESTAURANT",
      desc: "On-Site Dining Experience at O' Kareem Restaurant",
      icon: <FastfoodIcon style={{ color: "#00BCFD", }} />, // Mui icon for On-Site Restaurant
    },
  ];

  // You can now use the amenitiesData array in your application to display the information along with the relevant Mui icons.

  return (
    <div style={{ display: "flex", padding: "30px", background:"#FCF2DE" }}>
      {/* <div><WifiIcon/>WIFI</div>
            <div><RoomServiceIcon/>Room Service</div>
            <div><RestaurantIcon/>ON-SITE RESTAURANT</div>
            <div><LiveTvIcon/>STREAMING TV</div>
            <div><SecurityIcon/>Security</div>
            <div><LocalParkingIcon />Car Parking</div> */}

      <Grid container spacing={2}>
        {amenitiesData.map((res, index) => {
          return (
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <div style={{ borderRadius: "8px", width: "100%" }}>
                <div
                  id={index}
                  style={{ textAlign: "center", marginBottom: "10px",  }}
                >
                  {res?.icon}{" "}
                </div>
                <div style={{ textAlign: "center", fontWeight: "600" }}>
                  {res?.title}
                </div>
                <div style={{ fontSize: "12px", textAlign: "center" }}>
                  {res?.desc}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

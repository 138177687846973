import React from 'react'
import PackageDetail from '../components/PackageDetail'
import SliderCarousel from '../components/SliderCarousel'
import { HeroSection } from '../components/HeroSection'
import Facilities from '../components/Facilities'
import { Box } from '@mui/material'

const Home = () => {
  return (
    <>
     <HeroSection />
     <SliderCarousel />
     <PackageDetail />
     <Facilities/>
     <Box style={{width:"100%",}}><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.7482741293557!2d85.8406887!3d20.269272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a74fd22d021b%3A0x3aa43f8f6a833ec9!2sHotel%20Jajati!5e0!3m2!1sen!2sin!4v1716547857590!5m2!1sen!2sin" width="100%" height="600px"  style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></Box>
    </>
  )
}

export default Home
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

// import { primary } from "@/src/SDK/theme";

import useContactForm from "./hooks/useContactForm";
// import { MemoizedNameField, MemoizedPhoneField } from "@/src/SDK/input";
// import { MemoizedButton } from "@/src/SDK";
// import { checkError } from "@/src/SDK/utils";

//import { MemoizedSelectDropDown } from "@/src/SDK/selectDropdown/selectDropdown";
// import { Context } from "@/src/context/context";
//import { useRouter } from "next/router";
import { primary } from "../../SDK/theme";
import { Context } from "../../context/context";
import { MemoizedPhoneField } from "../../SDK/input/phoneInput";
import { checkError } from "../../SDK/utils/validationHelpers";
import { MemoizedNameField } from "../../SDK/input/nameInput";
import { MemoizedButton } from "../../SDK/button";

const CustomContactForm = styled(Box)(({ theme }) => ({
  background: primary?.white,
  maxWidth: "600px",
  //padding:"10px",
  background: primary.lightYellowBg,
  border: `1px solid ${primary?.main}`,
  //borderRadius: "16px",

  ".stats-image": {
    // width:"100%",
    maxHeight: "260px",
  },

  ".header-info": {
    fontSize: "34px",
    fontWeight: "700",
    lineHeight: "52px",
    letterSpacing: "0em",
    textAlign: "left",
    color: primary?.flagSaffron,
  },

  ".form-header": {
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "46px",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: "10px",
    marginBottom: "10px",
  },

  ".input-field": {
    border: "0px solid transparent",
    width: "100%",
    height: "60px",
  },

  ".input-field-phone": {
    border: "0px solid transparent",
    width: "100%",
    padding: "0px",

    ".MuiFilledInput-root": {
      paddingRight: "0px",
      height: "60px",
    },
  },

  ".submit-cta": {
    border: "transparent",
    margin: "none",
    //padding: "14px 20px",
  },

  ".thank-you": {
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "39px",
    letterSpacing: "0em",
    color: primary?.fantaOrange,
  },

  ".completed-desc": {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    color: primary?.main,
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  ".contact-info": {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "0em",
    color: primary?.main,
  },

  ".trial-info": {
    fontSize: "20px",
    lineHeight: "40px",
    color: primary?.darkYellow,
    marginTop: "80px",
    background: "red",
  },

  [theme.breakpoints.down("md")]: {
    ".header-info": {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "52px",
      letterSpacing: "0em",
      textAlign: "left",
      color: primary?.darkGreen,
    },

    ".form-header": {
      color: primary?.greyText,
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "46pxpx",
      letterSpacing: "0em",
      textAlign: "left",
      marginTop: "10px",
      marginBottom: "10px",
    },

    ".stats-image": {
      // width:"100%",
      maxHeight: "160px",
    },
  },

  [theme.breakpoints.down("sm")]: {
    ".header-info": {
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "52px",
      letterSpacing: "0em",
      textAlign: "left",
      color: primary?.main,
    },

    ".form-header": {
      color: primary?.main,
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "46px",
      letterSpacing: "0em",
      textAlign: "left",
      marginTop: "10px",
      marginBottom: "10px",
    },

    ".stats-image": {
      // width:"100%",
      maxHeight: "100px",
    },
  },
}));

const ContactForm = () => {
  const { form, loading, formSubmit } = useContactForm();
  const state = useContext(Context);
  const navigate = useNavigate();

  var trial = "go";

  useEffect(() => {
    if (formSubmit === true) {
      setTimeout(function () {
        state?.path?.length > 0 && navigate.push(state.path);
      }, 3000);
    }
  }, [formSubmit]);

  return (
    <CustomContactForm>
      {formSubmit ? (
        <Box
          style={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            padding: "4%",
          }}
        >
          <img src={"/images/logo.webp"} className="stats-image" />

          <Typography className="thank-you">Thank you!</Typography>
          <Typography
            className="completed-desc"
            style={{ color: primary.mainText }}
          >
            Thanks for reaching out to us at Stalwart
          </Typography>
          <Typography
            className="contact-info"
            style={{ color: primary.mainText }}
          >
            A member of our dedicated staff will review your message shortly and
            will get back to you soon
          </Typography>
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
          sx={{ p: { xs: "16px 16px 64px 24px", md: "1px 0px 0px 0px" } }}
        >
          <Grid item sm={12} md={12} style={{ width: "100%" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              //minHeight="100vh"
              width={"100%"}
              sx={{ p: { md: "20px", sm: "10px" } }}
            >
              <Box>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <img src={"/images/logo.webp"} className="stats-image" />
                </Box>

                {/* <Typography className="header-info">
                  Let’s plan your dream home together!
                </Typography> */}
                <Typography
                  className="form-header"
                  style={{ textAlign: "center" }}
                >
                  Connect with us
                </Typography>

                <Box>
                  <form onSubmit={form.handleSubmit}>
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ width: "100%" }}
                      >
                        <MemoizedNameField
                          className="input-field"
                          name="name"
                          error={!!checkError("name", form)}
                          helperText={form.errors.name}
                          placeholder="Your Name"
                          value={form.values.name}
                          onChange={(e) => {
                            form.handleChange(e);
                          }}
                        />
                      </Grid>

                      <Grid item lg={12} style={{ width: "100%" }}>
                        <MemoizedPhoneField
                          sx={{ minWidth: "100% !important" }}
                          error={!!checkError("mobile", form)}
                          helperText={form.errors.mobile}
                          className="input-field-phone"
                          name="mobile"
                          placeholder="Your Mobile"
                          value={form.values.mobile}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            form.handleChange(e);
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ width: "100%" }}
                      >
                        <MemoizedNameField
                          className="input-field"
                          name="message"
                          error={!!checkError("message", form)}
                          helperText={form.errors.message}
                          placeholder="Type Your Message Here"
                          multiline
                          rows={4}
                          value={form.values.message}
                          onChange={(e) => {
                            form.handleChange(e);
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ width: "100%", marginTop: "60px" }}
                      >
                        <MemoizedButton
                          content={"Submit"}
                          type={"submit"}
                          sx={{
                            textAlign: "center",
                            borderRadius: "50px",
                            background: primary?.main,
                            color: primary?.white,
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </CustomContactForm>
  );
};

export const MemoizedContactForm = React.memo(ContactForm);

import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import SecurityIcon from "@mui/icons-material/Security";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import CastleIcon from "@mui/icons-material/Castle";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import bgImage from "../assets/stalwart/hotel3.webp"
import PackageDetail from "../components/PackageDetail";
import SliderCarousel from "../components/SliderCarousel";


const amenitiesData = [
    {
      title: "WIFI",
      desc: "Complimentary Wi-Fi",
      icon: <WifiIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Wi-Fi
    },
    {
      title: "Room Service",
      desc: "Convenient Room Service",
      icon: <RoomServiceIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Room Service
    },
    {
      title: "STREAMING TV",
      desc: "Streaming TV Access",
      icon: <LiveTvIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Streaming TV
    },
    {
      title: "Security",
      desc: "Enhanced Security Measures",
      icon: <SecurityIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Security
    },
    {
      title: "WEDDING HALLS",
      desc: "Elegant Wedding Venues",
      icon: <CastleIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Wedding
    },
    {
      title: "Pick Up And Drop",
      desc: "Hassle-Free Pick-Up and Drop-Off Services",
      icon: <ConnectingAirportsIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Pick Up and Drop
    },
    {
      title: "FREE PARKING",
      desc: "Free Parking Facilities",
      icon: <LocalParkingIcon style={{ color: "#00BCFD", fontSize:"40px" }} />, // Mui icon for Free Parking
    },
    {
      title: "ON-SITE RESTAURANT",
      desc: "On-Site Dining Experience at O' Kareem Restaurant",
      icon: <FastfoodIcon style={{ color: "#00BCFD", }} />, // Mui icon for On-Site Restaurant
    },
  ];

const Services = () => {
  return (
    <Box >
      {/* <IntroDivider /> */}
      <img src={bgImage} style={{maxHeight:"550px", objectFit:"cover", width:"100%"}}/>
      <Typography sx={{fontSize:{xs:"20px", sm:"24px", md:"30px", lg:"36px", textAlign:"center", fontWeight:"600", marginTop:"16px"}}}>Our Services</Typography>
      <Box sx={{padding:{xs:"10px", sm:"20px",md:"30px", lg:"40px"}}}>
      <Grid container spacing={3}>
        {amenitiesData.map((amenity, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
            <Card>
              <CardContent>
                <Box style={{ textAlign: "left", marginBottom:"20px" }}>
                  
                  <Typography variant="h5"><span>{amenity.title}</span> <span style={{float:"right"}}>{amenity.icon}</span></Typography>
                  <Typography>{amenity.desc}</Typography>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <Typography gutterBottom variant="body2">
                    Room Type
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip  label="Deluxe Room" size="small" />
                    <Chip label="Executive Room" size="small" />
                    <Chip label="Executive Suite" size="small" />
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid></Box>
      <PackageDetail/>
      <SliderCarousel />
      {/* <Facilities /> */}
    </Box>
  );
};

export default Services;

import React, { useState } from "react";
import { Box, Button, Typography, Stack, Grid } from "@mui/material";
import Hotel1 from "../assets/stalwart/hotel1.webp";
import SliderCarousel from "../components/SliderCarousel";

const AboutUsDetailsComponent = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Box
    // sx={{backgroundImage:"url('/images/building/skycityv2.webp')", backgroundRepeat:"no-repeat", backgroundSize:{lg:"contain",md:"contain", sm:"", xs:""}}}
    >
      <Box>
        <img
          src={Hotel1}
          style={{
            width: "100%",
            maxHeight: "500px",
            objectFit: "cover",
            transition: "transform 0.3s linear", // Added transition for smooth zoom
          }}
          onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")} // Applies zoom on hover
          onMouseLeave={(e) => (e.target.style.transform = "scale(1)")} // Reverts zoom on mouse leave
        />
      </Box>

      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          padding: { md: "60px", lg: "60px", xs: "20px" },
          borderRadius: "16px",

          // height: "500px",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "32px", md: "28px", xs: "22px", sm: "22px" },
            fontWeight: "600",
            textAlign: "left",
            paddingBottom: { xs: "20px" },
          }}
        >
          ABOUT US
        </Typography>

        <Grid container spacing={1} sx={{ width: "100%" }}>
          {/* <Grid item xs={12} sm={4} md={4} lg={4}>
          <Box
            sx={{ padding: { lg: "20px", xs: "0px" }, paddingTop:{ lg: "0px", xs: "0px" } }}
          >

            <img src="/images/building/award1.webp" style={{width:"100%", borderRadius:"8px"}}/>
            
          </Box>
        </Grid> */}

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ padding: { lg: "20px", xs: "0px" } }}>
              

              <Grid container >
                <Grid item lg={7} md={12} sm={12} xs={12} width={"100%"}>

                  <Box style={{display:"block"}}>

                <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                About Hotel Stalwart Jajati:
              </Typography>
                  {" "}
                  <Typography
                    style={{ fontSize: "18px", marginBottom: "20px" }}
                  >
                    Immerse yourself in luxury and comfort at Hotel Stalwart
                    Jajati, proudly owned by Stalwart Projects Private Limited.
                    Conveniently located near the bustling Bhubaneswar railway
                    station, our recently renovated 3-star hotel offers 40
                    Executive Rooms and Suites designed for your ultimate
                    comfort. Experience modern amenities including
                    air-conditioning, complimentary WiFi, and private bathrooms
                    with hot water facilities. Unwind with a 32" LED TV, indulge
                    in refreshments with an electric coffee/tea maker, and stay
                    connected with a direct dial telephone. Our dedicated team
                    ensures round-the-clock room service and a 24-hour front
                    desk for a hassle-free stay. Delight your palate at our O'
                    Kareem Restaurant, where each dish is meticulously crafted
                    for your enjoyment.
                  </Typography>

                  <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                About Sahu Hotels Pvt Ltd (Hotel Jajati):
              </Typography>
              <Typography style={{ fontSize: "18px", marginBottom: "20px" }}>
                Established on June 21, 1982, Sahu Hotels Pvt Ltd has a
                longstanding reputation for top-notch hospitality. In 2023,
                under the guidance of Stalwart Projects Private Limited, Sahu
                Hotels Pvt Ltd underwent a significant transformation,
                reemerging as the prestigious Hotel Stalwart Jajati. This
                evolution continues our legacy of excellence, striving to exceed
                guest expectations and provide unparalleled comfort and
                convenience.
              </Typography>

              </Box>

                </Grid>
                <Grid item lg={5} md={12} sm={12} xs={12} width={"100%"} style={{display:'flex',justifyContent:'center'}}>
                  {" "}
                  <img
                    src={"/logo.png"}
                    style={{
                      maxHeight:"400px",
                    //  width: "300px",
                      // padding: "20px",
                      transition: "transform 0.3s ease-in-out", // Added transition for smooth zoom
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.transform = "scale(1.2)")
                    } // Applies zoom on hover
                    onMouseLeave={(e) =>
                      (e.target.style.transform = "scale(1)")
                    } // Reverts zoom on mouse leave
                  />
                </Grid>
              </Grid>

             
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                About Stalwart Projects Private Limited:
              </Typography>

              <Grid container style={{ display: "flex" }}>
                <Grid
                  item
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                  width={"100%"}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={"/stalwart-logo.webp"}
                    style={{
                      maxHeight: "400px",
                      alignItems: "center",
                      transition: "transform 0.3s ease-in-out", // Added transition for smooth zoom
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.transform = "scale(1.2)")
                    } // Applies zoom on hover
                    onMouseLeave={(e) =>
                      (e.target.style.transform = "scale(1)")
                    } // Reverts zoom on mouse leave
                  />
                </Grid>
                <Grid item lg={9} md={12} sm={12} xs={12} width={"100%"}>
                  <Typography style={{ fontSize: "18px" }}>
                    For over 13 years, Stalwart Projects has been a leader in
                    redefining real estate development, crafting exquisite
                    residential and commercial spaces that epitomize
                    sophistication and functionality. Our unwavering commitment
                    to excellence has earned the trust of a diverse clientele,
                    from individuals seeking luxury homes to businesses in need
                    of innovative workspaces. With a vision of creating
                    remarkable spaces, we embrace progressive techniques and
                    uphold the highest standards of quality, integrity, and
                    client satisfaction. Join the Stalwart family today and
                    experience our dedication to turning your real estate dreams
                    into extraordinary realities.
                  </Typography>
                </Grid>
              </Grid>

              {/* <Typography style={{ fontSize: "18px" }}>
                <span style={{ fontWeight: "600" }}>Quality : </span> Upholding
                the highest standards across design, construction, and service.
                <Typography style={{ fontSize: "18px" }}>
                  <span style={{ fontWeight: "600" }}>Integrity : </span>
                  Fostering trust and transparency in every interaction.
                </Typography>
                <span style={{ fontWeight: "600" }}>Innovation : </span>{" "}
                Embracing progressive techniques to enhance our creations.
              </Typography>
              <Typography style={{ fontSize: "18px", marginBottom: "20px" }}>
                <span style={{ fontWeight: "600" }}>Client Satisfaction: </span>{" "}
                Going the extra mile to exceed expectations and forge lasting
                relationships.
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Uncompromising Excellence in Every Aspect
              </Typography>
              <Typography style={{ fontSize: "18px" }}>
                <span style={{ fontWeight: "600" }}>
                  Cutting-Edge Technology:
                </span>{" "}
                Employing state-of-the-art construction methods and equipment.
                <Typography style={{ fontSize: "18px" }}>
                  <span style={{ fontWeight: "600" }}>Seasoned Expertise:</span>{" "}
                  Bringing decades of industry knowledge to every project.
                </Typography>
                <Typography style={{ fontSize: "18px" }}>
                  <span style={{ fontWeight: "600" }}>Modern Design:</span>{" "}
                  Inspiring spaces that merge aesthetics with practicality.
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "18px", fontWeight: "600" }}>
                    Customer Support:
                  </span>{" "}
                  Dedicated, round-the-clock assistance for all inquiries.
                </Typography>
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                Your Trusted Partner in Real Estate Success
              </Typography> */}

              {/* <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                Join the Stalwart Family
              </Typography>
              <Typography style={{ fontSize: "18px", marginBottom: "20px" }}>
                Having satisfied over 1000 clients with exceptional projects,
                Stalwart Projects invites you to experience our unwavering
                commitment to quality, innovation, and client satisfaction.
                Contact us today to turn your real estate dreams into
                extraordinary realities.
              </Typography> */}
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <SliderCarousel />
    </Box>
  );
};

export default AboutUsDetailsComponent;

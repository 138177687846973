export const postExcelLead = (payload) => {
  const networkAPI = `https://script.google.com/macros/s/AKfycbzvqXI6n68K1kepW9hiGLXNdM5d6pVt7u4mgyk1oOXvn2LcJSZ13BiKeGM5Ot8G0oRP/exec`;

  console.log(payload, "Deepak");
  const WEBAPP_URL = `${networkAPI}?name=${payload?.name}&phone=${payload?.mobile}&message=${payload?.message}`;

  fetch(`${WEBAPP_URL}`, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    withCredentials: true,
    credentials: "same-origin",
  })
    .then((res) => {
      console.log("Test1", res);
    })
    .catch((error) => {
      console.log("error", error);
    });
};

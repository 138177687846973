import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { HotelDetail } from '../Detail/HotelDetail';
import img1 from "../assets/stalwart/hotel1.webp"
import img2 from "../assets/stalwart/hotel3.webp"
import img3 from "../assets/stalwart/hotel4.webp"
import img4 from "../assets/stalwart/hotel6.webp"
import img5 from "../assets/stalwart/hotel7.webp"

const SliderCarousel = () => {
  
  const hotelInfo=[{id:1,image:img1},
    {id:2,image:img2},
    // {id:3,image:img3},
    {id:4,image:img4},
    {id:5,image:img5}
  ]

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={3}
      modules={[Navigation, Pagination, Autoplay]}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      autoplay={{"delay": 3000,}}
    >
      {hotelInfo.map(img => <SwiperSlide key={img.id}><img src={img.image}  alt=""/></SwiperSlide>)}
    </Swiper>
  );
};

export default SliderCarousel;
import image from '../assets/stalwart/hotel7.webp'
import SliderCarousel from '../components/SliderCarousel'
import PackageDetail from '../components/PackageDetail'
import Facilities from '../components/Facilities'
import { Typography } from '@mui/material'

export default function RoomsAndSuits(){
    return(
        <div><img src={image} style={{maxHeight:"520px", width:'100%', objectFit:"cover"}}></img>
      <Typography sx={{fontSize:{xs:"20px", sm:"24px", md:"30px", lg:"36px", textAlign:"center", fontWeight:"600", marginTop:"16px", margin:"20px"}}}>Our Services</Typography>
      
        <PackageDetail />
        <Facilities/>
        <SliderCarousel />
        </div>
        
    )
}